import { render, staticRenderFns } from "./popo-minting-loading-dialog.vue?vue&type=template&id=bf445e94&scoped=true&"
import script from "./popo-minting-loading-dialog.vue?vue&type=script&lang=ts&"
export * from "./popo-minting-loading-dialog.vue?vue&type=script&lang=ts&"
import style0 from "./popo-minting-loading-dialog.vue?vue&type=style&index=0&id=bf445e94&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf445e94",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VDialog,VImg,VProgressCircular})
